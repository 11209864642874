<template>
  <VTable
    :head-row="headRow"
    :rows="rows"
    :is-checkbox="false"
    url-set-rows="/accreditation"
    :transform-object="transformObject"
    url-action="/users/action"
    url-action-query="search[Active]=archive"
    @link="handlerLink"
  />
</template>

<script setup>
import { computed, defineComponent } from 'vue'

import VTable from '@/components/ui/table/VTable'
import { useStore } from 'vuex'

// Lets
const store = useStore()
const headRow = [
  {
    name: 'Наименование',
    value: 'name',
    filter: true
  },
  {
    name: 'Дата/время заявки',
    value: 'created_at',
    filter: true
  },
  {
    name: 'ФИО ответственного',
    value: 'fio',
    filter: true
  },
  {
    name: 'Дата/время аккредитации',
    value: 'updated_at',
    filter: true
  },
  {
    name: 'Статус',
    value: 'status',
    filter: true
  }
]

const transformObject = (data) => {
  data.map((item) => {
    if (!item.user) {
      return null
    }

    item.name = item.user.organization_name
    item.fio = item.user.trusted_person
    // item.created_at = item.user.created_at.slice(0, 19).replace('T', ' ')
    // item.updated_at = item.user.updated_at.slice(0, 19).replace('T', ' ')
  })

  return data
}

const handlerLink = () => {
}

const rows = computed(() => {
  return store.getters['item/getItems']
})

defineComponent({
  VTable
})

</script>
